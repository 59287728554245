import { Flex, Button, useToast, Stack, Center, useBreakpointValue} from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { getUser } from '@utility/UserHelper'
import { useChatbot } from '@hooks/useChatbot'
import ChatbotCardV2 from '@components/card/ChatbotCardV2'
import { useParams } from 'react-router-dom'

const ChatbotListing = () => {
    const toast = useToast()
    const chatbot = useChatbot(toast)
    const navigate = useNavigate()
    const user = getUser()
    const flexDirection = useBreakpointValue({ base: 'column', md: 'row' })
    const { orgId }  = useParams() 
    console.log(user)
    const onClick = () => {
        navigate(`/orgs/${orgId}/chatbot`)
    }

    const chatbotsQuery = useQuery({
        queryKey: ['user_chatbots', user?.id],
        queryFn: () => chatbot.getChatbots(),
        enabled: !!user?.id,
        initialData: {items: [{id: 1, short_code: "1234", sheet_id: "1", template: "sop"}]}
    })

    const updateChatbot = async (info) => {
        const response = await chatbot.updateChatbot(info?.short_code, {template: info?.template, sheet_id: info?.sheet_id})
        console.log(response)
        chatbotsQuery.refetch()
    }

    const deleteChatbot = async (info) => {
        const response = await chatbot.deleteChatbot(info?.short_code, {template: info?.template, sheet_id: info?.sheet_id})
        console.log(response)
        chatbotsQuery.refetch()
    }

    const startFlow = async (info) => {
        const response = await chatbot.startChatbot(info?.short_code, {})
        console.log(response)
    }

    const restartFlow = async (info) => {
        const response = await chatbot.restartChatbot(info?.short_code, {})
        console.log(response)
    }

    const clearSource = async (info) => {
        const response = await chatbot.clearSource(info?.short_code, {})
        console.log(response)
    }

    const refreshFlow = async (info) => {
        const response = await chatbot.refreshTasks(info?.short_code, {})
        console.log(response)
    }

    return (
        <Center minHeight = "100vh" minWidth = "100vw">
            <Flex justifyContent="center" alignItems="center" flexDirection={flexDirection} wrap="wrap">
                <Stack mt={4} height={"100%"}>
                    <Button variant="brandPrimary" size="lg" m={2}  onClick={onClick} >
                    Link Google Sheet
                    </Button>
                    {chatbotsQuery && chatbotsQuery?.data?.items?.map((chatbot) => (
                        <ChatbotCardV2 
                            key={chatbot.id}
                            info={chatbot}
                            onUpdate={()=> updateChatbot(chatbot)}
                            onDelete={()=> deleteChatbot(chatbot)}
                            onStart={()=> startFlow(chatbot)}
                            onRestart={()=> restartFlow(chatbot)}
                            onClear={()=> clearSource(chatbot)}
                            onRefresh={()=> refreshFlow(chatbot)}
                        />
                    ))}
                </Stack>
            </Flex>
        </Center>
    )
}

export default ChatbotListing